import { useContext } from 'react';
import DropdownContext from './DropdownContext';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useDropdown() {
  const context = useContext(DropdownContext);
  if (context === undefined) {
    throw new Error('useDropdown must be used within a DropdownContextProvider');
  }
  return context;
}
