import * as React from 'react';

import { withStyles, WithStyles } from '@nelnet/unifi-components-react';

import styles from './styles';
import { useDropdown } from './useDropdown';

import { GrClose } from 'react-icons/gr';
import { GiHamburgerMenu } from 'react-icons/gi';

interface IDropdownProps extends WithStyles<typeof styles> {
  button_class?: string;
}

const Dropdown: React.FunctionComponent<IDropdownProps> = ({ button_class }) => {
  const { isDropdownOpen, setIsDropdownOpen } = useDropdown();
  return (
    <>
      <button
        className={button_class}
        onClick={() => {
          setIsDropdownOpen(!isDropdownOpen);
        }}
      >
        {isDropdownOpen ? (
          <GrClose aria-label={'Close navigation menu'} />
        ) : (
          <GiHamburgerMenu aria-label={'Open navigation menu'} />
        )}
      </button>
    </>
  );
};

export default withStyles(styles)(Dropdown);
