import * as React from 'react';

import { WithStyles, withStyles } from '@nelnet/unifi-components-react';

import AppBar from '../AppBar';

import styles from './styles';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import favicon from '../../../images/favicon.svg';

// Google Analytics incorporation
import ReactGA from 'react-ga';
ReactGA.initialize('UA-177024637-1');
ReactGA.pageview(
  typeof window !== 'undefined' ? window.location.pathname + window.location.search : '',
);

interface ILayoutFrame extends WithStyles<typeof styles> {
  currPath?: string;
  page_color?: string;
}

const LayoutFrame: React.FunctionComponent<ILayoutFrame> = ({ children, classes, currPath }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <AppBar currPath={currPath} />
      <main className={classes.main}>{children}</main>
    </HelmetProvider>
  );
};

export default withStyles(styles)(LayoutFrame);
