import { createContext } from 'react';

interface IDropdownContext {
  isDropdownOpen: boolean;
  setIsDropdownOpen: (value: boolean) => void;
}

export const DropdownContext = createContext<IDropdownContext | undefined>(undefined);

export default DropdownContext;
