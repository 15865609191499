import * as React from 'react';

import clsx from 'clsx';

import {
  AppBar as UnifiAppBar,
  Toolbar,
  Grid,
  Link,
  WithStyles,
  withStyles,
} from '@nelnet/unifi-components-react';

import usePrismicNavItems, { ISiteNavItem } from '../../data/prismic/usePrismicNavData';
import Dropdown from './Dropdown';
import { DropdownContext } from './DropdownContext';
import styles from './styles';

import logo from '../../../images/logo.svg';

interface IAppBarProps extends WithStyles<typeof styles> {
  currPath?: string;
}

const AppBar: React.FunctionComponent<IAppBarProps> = ({ classes, currPath }) => {
  const navItems: ISiteNavItem[] = usePrismicNavItems();
  const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);

  return (
    <UnifiAppBar component="header" color="neutral" position="sticky" role="banner">
      <Toolbar className={classes.toolBar} id="desktopMenu">
        <Link underline="none" href="/">
          <img
            src={logo}
            alt="Unifi"
            className={classes.appBarIcon}
            tabIndex={0}
            onClick={() => window.scrollTo(0, 0)}
          />
        </Link>
        <nav className={classes.desktopNavBar} role="navigation" aria-label="Main">
          <Grid container spacing={2} component="ul" className={classes.appBarNav}>
            {navItems.map((item) => {
              const active = (currPath && currPath === item.slug && classes.activeNav) || '';
              if (item.menuOrder !== 0) {
                return (
                  <Grid item component="li" key={item.slug}>
                    <Link
                      underline="none"
                      href={item.slug}
                      className={clsx(active, classes.navLink)}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {item.title}
                    </Link>
                  </Grid>
                );
              }
            })}
          </Grid>
        </nav>
      </Toolbar>
      <Toolbar className={classes.mobileToolBar} id="mobileMenu">
        <Link underline="none" href="/">
          <img
            src={logo}
            alt="Unifi"
            className={classes.appBarIcon}
            tabIndex={0}
            onClick={() => window.scrollTo(0, 0)}
          />
        </Link>
        <DropdownContext.Provider value={{ isDropdownOpen, setIsDropdownOpen }}>
          <Dropdown button_class={classes.toggleMenu} />
        </DropdownContext.Provider>
        <nav
          role="navigation"
          aria-expanded={isDropdownOpen ? 'true' : 'false'}
          aria-hidden={isDropdownOpen ? 'false' : 'true'}
          className={isDropdownOpen ? classes.menuOpen : classes.menuClosed}
        >
          <Grid container spacing={2} component="ul" className={classes.appBarNav}>
            {navItems.map((item) => {
              const active = (currPath && currPath === item.slug && classes.activeNav) || '';
              if (item.menuOrder !== 0) {
                return (
                  <Grid item component="li" key={item.slug}>
                    <Link
                      underline="none"
                      href={item.slug}
                      className={clsx(active, classes.navLink)}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {item.title}
                    </Link>
                  </Grid>
                );
              }
            })}
          </Grid>
        </nav>
      </Toolbar>
    </UnifiAppBar>
  );
};

export default withStyles(styles)(AppBar);
