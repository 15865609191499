import { createStyles } from '@nelnet/unifi-components-react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const styles = () =>
  createStyles({
    main: {
      width: '100%',
      margin: '0 auto',
      textAlign: 'center',
    },
    '@global': {
      a: {
        color: '#0E729A',
      },
    },
  });

export default styles;
