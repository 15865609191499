import { createStyles, Theme } from '@nelnet/unifi-components-react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const styles = ({ palette, breakpoints, spacing }: Theme) =>
  createStyles({
    toolBar: {
      backgroundColor: '#ffffff',
      display: 'none',
      [breakpoints.up('sm')]: {
        display: 'inline-flex',
        paddingLeft: '0.5rem',
        minHeight: '80px',
      },
      [breakpoints.up('md')]: {
        padding: '1rem 3rem 1rem 2rem',
        minHeight: '100px',
      },
    },
    mobileToolBar: {
      minHeight: '80px',
      backgroundColor: '#ffffff',
      padding: '0 !important',
      [breakpoints.up('sm')]: {
        display: 'none',
        padding: '16px',
      },
    },
    appBarIcon: {
      display: 'block',
      margin: `${spacing(1)}px ${spacing(3)}px`,
      width: '170px',
      position: 'absolute',
      zIndex: 3,
      top: '1rem',
      outlineOffset: '4px',
      [breakpoints.up('sm')]: {
        margin: `${spacing(1)}px 4px`,
      },
      [breakpoints.up('md')]: {
        outlineOffset: '10px',
        maxHeight: '3rem',
        maxWidth: '100%',
        height: 'auto',
        width: '225px',
      },
    },
    appBarNav: {
      listStyle: 'none',
      padding: 0,
      display: 'block',
      '& li': {
        top: '24px',
        textAlign: 'center',
        [breakpoints.up('sm')]: {
          position: 'unset',
          display: 'inline-flex',
          padding: '0 !important',
          textAlign: 'left',
        },
      },
      //
      '& li:last-child': {
        backgroundColor: '#AED136',
        padding: '2px !important',
        marginLeft: '2px',
        [breakpoints.down('xs')]: {
          padding: '0.5rem !important',
          backgroundColor: palette.background.main,
        },
      },
    },
    desktopNavBar: {
      marginLeft: 'auto !important',
    },
    navLink: {
      fontFamily: 'Red Hat Display',
      color: '#000000',
      fontSize: '0.8125rem',
      fontWeight: 700,
      height: '40px',
      letterSpacing: '2px',
      lineHeight: '1',
      textAlign: 'center',
      textTransform: 'uppercase',
      padding: '14px 5px 0',
      width: '130px',
      [breakpoints.up('md')]: {
        width: '160px',
        padding: '14px 10px 0',
      },
      '&:hover': {
        // TODO: figure out how to make underline color match page_color variable
        borderBottom: '2px solid currentColor',
      },
    },
    activeNav: {
      fontWeight: 700,
      borderBottom: '2px solid currentColor',
    },
    mobileMenuIcon: {
      zIndex: 3,
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 0,
      width: '50px',
      border: '0',
      backgroundColor: '#ffffff',
      color: '#000000',
      cursor: 'pointer',
      fontSize: '1rem',
      padding: '0.75rem',
      [breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    mobileMenuButton: {
      display: 'block',
      position: 'absolute',
      top: '1.25rem',
      right: '2rem',
      width: '50px',
      height: '3.1rem',
      border: '0',
      backgroundColor: '#ffffff',
      cursor: 'pointer',
      padding: '0.75rem',
      [breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    menuOpen: {
      position: 'absolute',
      width: '100%',
      zIndex: 2,
      top: '100%',
      padding: '1rem 0',
      backgroundColor: palette.background.main,
      [breakpoints.up('sm')]: {
        marginLeft: 'auto !important',
      },
      [breakpoints.up('md')]: {
        display: 'none',
      },
    },
    menuClosed: {
      display: 'none',
    },
    toggleMenu: {
      position: 'absolute',
      right: '2rem',
      border: 'none',
      background: 'transparent',
      fontSize: '1.75rem',
    },
  });

export default styles;
