import { graphql, useStaticQuery } from 'gatsby';

export interface IPrismicNode {
  _meta: {
    uid: string;
    type: string;
  };
  menu_order: number;
  title: [
    {
      text?: string;
    },
  ];
}

export interface ISiteNavItem {
  slug: string;
  title?: string | null;
  menuOrder?: number | null;
}

interface IPrismicData {
  prismic: {
    _allDocuments: {
      edges: [
        {
          node: IPrismicNode;
        },
      ];
    };
  };
}

/*
 * Fetch Prismic data for use in navigation
 */
const getPrismicNavData = () => {
  const { prismic }: IPrismicData = useStaticQuery(
    graphql`
      query {
        prismic {
          _allDocuments(tags: "Nav-Bar") {
            edges {
              node {
                _meta {
                  uid
                }
                ... on PRISMIC_Homepage {
                  menu_order
                  title
                }
                ... on PRISMIC_Capabilities {
                  menu_order
                  title
                }
                ... on PRISMIC_Guidelines {
                  menu_order
                  title
                }
                ... on PRISMIC_Connect {
                  menu_order
                  title
                }
              }
            }
          }
        }
      }
    `,
  );

  const prismicData = prismic._allDocuments.edges;

  return (
    prismicData.map(({ node }) => {
      const uid = node._meta.uid;
      const menuOrder = node.menu_order;
      const title = node.title && node.title[0].text;
      const slug = uid === 'home' ? '/' : `/${uid}/`;

      return {
        slug,
        title,
        menuOrder,
      };
    }) || []
  );
};

/*
 * Sort Prismic data for use in navigation
 *
 * Priority is menuOrder, then alphabetical
 */
const usePrismicNavItems = (): ISiteNavItem[] => {
  const prismicNav: ISiteNavItem[] = getPrismicNavData();

  const navSorted = prismicNav.sort((a, b) => {
    const orderA = a.menuOrder === null ? 99 : a.menuOrder;
    const orderB = b.menuOrder === null ? 99 : b.menuOrder;
    if (orderA < orderB) {
      return -1;
    }
    if (orderA > orderB) {
      return 1;
    }

    const nameA = a.title;
    const nameB = b.title;
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  return navSorted;
};

export default usePrismicNavItems;
